import { useState, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import styled from "styled-components"
import images from '../images'
import Navbar from '../Navbar'
import Search from './Search'
import Banner from "./Banner"
import Footer from "./Footer"
import Section1 from "./Section1"
import Section3 from "./Section3"
import Section4 from "./Section4"
const DivC = styled.div`
background: linear-gradient(#000,#111); 
/* background-color: #; */
min-height:100vh;
position: relative;
overflow: hidden;
.imgl{
    
    width: 80%;
    animation:an-image 1s;
    /* max-width: 500px; */
    transform-origin: 100% 0;
}
@keyframes an-image{
    from{
        transform:scale(.8) translateX(20px);opacity:0;
    }
    to{
        transform:scale(1);opacity:1
    }
}
@keyframes top-bottom{
    from{transform: translateY(-30px);opacity:0}
    to{transform:none;opacity:1}
}
@keyframes bottom-top{
    from{transform: translateY(30px);opacity:0}
    to{transform:none;opacity:1}
}
.an-top-bottom{animation:top-bottom .6s;}
.an-bottom-top{animation:bottom-top .6s;}
.title{
    font-size: 46px;
    font-weight: 700;
    color:#FFF;
    line-height: 45px;
}
.container{
    position: relative
}
.sub-title{
    font-size: 18px;
}

.pr{
    position:relative
}
.app{
    width:100%
}
.app img{
    height:60px
}

.app a{
    /* overflow: hidden; */
    border-radius:5px;
    border:1px solid #EEE3;
    /* box-shadow: 0 0 1px 0 #FFF5; */
}
.fixedSearch .div-search .inner{
    box-shadow: 0px 0px 3px 0px #0002;
}
.resp{
    min-height:214px;
}
@media screen and (max-width: 992px){
    .title{
        text-align: center;
        font-size: 32px;
        margin-top: 34px;
    }
    .sub-title{
        font-size: 14px;
        text-align: center;
    }
    .subback{
        bottom:10px;
    }
    .app{
        justify-content: space-evenly;
        margin-bottom:16px
    }
    .app img{
        height:50px
    }
    .resp{
        min-height:352px;
    }
   
}
@media screen and (max-width: 400px){
   
    .app img{
        height:44px
    }
   
}
.nav-fixed{
    position: fixed;
    top:0;
    left: 0;right:0;
    background-color: #FDFDFD;
    box-shadow: 0px 0px 3px 0px #3333;
    z-index: 1200;
}
.nav-fixed .btn-search{
    display: flex;
}
.fixedSearch{
    position: fixed;
    top:80px;
    left: 0;right:0;
    background-color: #111;
    box-shadow: 0px 3px 3px 0px #3332;
    z-index: 1100;
    animation: trb .4s ;
}
.fixedSearch .div-search{
    max-width: 1000px;
}
@keyframes trb{
    from{
        transform:translateY(-100%);
    }
    to{
        transform:none;
    }
}
.bg-logo{
    background-size:auto 55% ;
    background-repeat: no-repeat;
    background-position: center top;
    padding-top:40px
}
.bottom-c{
    margin-top:56px
}

`
function Privateloc() {
    const [fixed, setFixed] = useState(false);
    const [fixedSearch, setFixedSearch] = useState(false);
    const stickNavbar = () => {

        setFixed(window.scrollY > 100)

        setFixedSearch(false);
    }
    const fixSearch = () => {
        console.log(fixedSearch)
        setFixedSearch(true);
    }
    useEffect(() => {
        window.addEventListener("scroll", stickNavbar);
        return () => {
            window.removeEventListener('scroll', stickNavbar);
        };
    }, []);
    return <>
        <DivC className='d-flex flex-column'>
            <div style={{height:"80px"}}>
            <div className={fixed ? "nav-fixed" : ""} style={{boxShadow:fixedSearch?"none":fixed?"0px 0px 3px 0px #3333":"none"}}>
                <Navbar btnClick={fixSearch} fixedSearch={fixedSearch} fixed={fixed} />
            </div>
            </div>


            <div className='d-flex align-items-center  flex-column justify-content-end pr flex-grow-1 bottom-c '>
                <div className='flex-grow-1 d-flex align-items-center p-3 container resp '  style={{ width: "100%"}}>
                    <div style={{ width: "100%" }} className={`an-top-bottom   d-flex align-items-center justify-content-center flex-grow-1 p-1  ${fixedSearch ? "fixedSearch" : ""}`}>
                        <Search />
                        {/* <iframe style={{overflow: "visible",borderRadius:"5px",transition:"height .1s"}} 
                        width="350" height="215px" allow="geolocation" id="bookingz" 
                        src='https://wasletuser.wegofleet.com/frame'
                        data-src="https://wasletuser.wegofleet.com/frame" class=" lazyloaded"></iframe> */}
                    </div>
                </div>
                <Container className="d-flex align-items-center pb-2">
                    <Row>
                        <Col lg={5} className='d-flex justify-content-center flex-column an-bottom-top' >
                            <h1 className='title mt-4'>Une nouvelle ère de la livraison à la demande </h1>
                            <p className='sub-title text-light'>Nous récupérons et livrons vos colis sur tout le territoire national.</p>
                            <div className='d-flex app'>
                                <a href="https://play.google.com/" target="_blank" className='me-3'>
                                    <img src={images.googleplay} alt="" />
                                </a>
                                <a href="https://www.apple.com/app-store/" target="_blank" >
                                    <img src={images.appstore} alt="" />
                                </a>


                            </div>
                        </Col>
                        <Col lg={7} className='d-flex align-items-center justify-content-center bg-logo mt-2' >
                            <img src={images.img_r} alt="" className="imgl" />
                        </Col>
                    </Row>
                </Container>

            </div>
        </DivC>
        <Section1 />
        <Section3 />
        <Banner />
       <Section4 />
        <Footer/>
        {/* 
        <Section2 />
        <Banner />
        
        
        <SectionLivrer/>
        
        <CookieModal/> */}
    </>
}

export default Privateloc








