import React from 'react'
import styled from "styled-components"
import { Container,Row,Col } from 'react-bootstrap'
import images from "../images/"
import {BiMessageSquareDetail} from "react-icons/bi"
import {FaLinkedin} from "react-icons/fa"
import {RiInstagramFill}from "react-icons/ri"
import {MdOutlineRemoveShoppingCart,MdFacebook,MdInsta} from "react-icons/md"

const DivC=styled.div`
color: #FFF;;
padding-top:30px;
padding-bottom:30px;
background-color: #000;
overflow: hidden;
.logow{
    height:40px
}
.linkM{
    color:#FFF;
    font-size:40px
}
.linkM:hover{
    color:#e22424
}
hr{
    border-color: #FFF1;
    margin:32px 20px
}
h1{
    font-size:16px;
    font-weight:600;
    margin-bottom:12px;
    text-transform: uppercase;
    color:#CCC
}
ul{
    list-style-type: none;
    padding:0;
    font-size:14px;
    color:#DDD

}
.app img{
    height:40px;
    border:1px solid #FFF3;
    border-radius:4px;
}
a{
    color:#FFF;
    /* text-decoration: none; */
}
@media screen and (max-width: 768px){
    .rw{
        text-align:center
    }
}
.mm{
    font-size:13px ;
    text-align:center
}
ul a{
    color:#FFF;
    text-decoration: none;
}
ul a:hover{
    color:#34df7d
}

`
function Footer() {
  return (
    <DivC>
        <Container>
            <Row>
                <Col lg={4} md={6} sm={12} className="d-flex align-items-center justify-content-center">
                    <img src={images.logov} alt="" className="logow" />
                </Col>
                <Col lg={4} md={6} sm={12} className="d-flex justify-content-center">
                    <a href="https://www.facebook.com/wegobox.world" target="_blank" className="linkM me-5">
                        <MdFacebook/>
                    </a>
                    <a href="" target="_blank" className="linkM me-5">
                        <RiInstagramFill/>
                    </a>
                    <a href="" target="_blank" className="linkM">
                        <FaLinkedin/>
                    </a>
                </Col>
                <Col lg={4} md={12} sm={12} className="d-flex align-items-center justify-content-center">
                    
                    <ul className="app d-flex align-items-center m-0">
                        <li className="me-2">
                            <a href="https://play.google.com/" target="_blank">
                                <img src={images.googleplay} alt="" />
                            </a>
                        </li>
                        <li>
                            <a href="https://www.apple.com/app-store/" target="_blank" >
                                <img src={images.appstore} alt="" />
                            </a>
                        </li>
                                
                    </ul>
                </Col>
            </Row>
            <hr />
            <Row className="rw">
                
                <Col lg={3} md={6} sm={12} >
                    <h1>DÉCOUVREZ PRIVATELOC</h1>
                    <ul>
                        <li>Notre équipe</li>
                        <li>Devenir caretaker</li>
                        <li>Recrutement</li>
                        <li>Presskit</li>
                    </ul>
                </Col>
                <Col lg={3} md={6} sm={12} >
                    <h1>NOTRE SERVICE</h1>
                    <ul>
                        <li>Comment ça marche</li>
                        <li>Professionnel</li>
                        <li>Nos assurances</li>
                        <li>Parrainage</li>
                    </ul>
                </Col>
                <Col lg={3} md={6} sm={12} >
                    <h1>Votre compte</h1>
                    <ul>
                        <li>Inscription</li>
                        <li>Connexion</li>
                        <li>Mot de passe oublié</li>
                        <li>Des questions ?</li>
                    </ul>
                </Col>
                <Col lg={3} md={6} sm={12} >
                    <h1>NOTRE  produits</h1>
                    <ul>
                        <li><a href="https://exped.wegobox.fr/" target="_blank" >Exped</a></li>
                    </ul>
                </Col>
                
            </Row>
            <hr />
            <Row>
                <Col className="d-flex justify-content-center mm">
                   
                        © 2022. All rights reserved by <a href="https://devgate.net/" target="_blank" className="ms-1"> DevGate</a>.
                   
                </Col>
            </Row>
        </Container>

    </DivC>
  )
}






export default Footer