
import React, { useState } from 'react';
import { IntlProvider } from 'react-intl';
import lang from './lang';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PrivatelocProvider from "./Components/Context/"
import Privateloc from './Components/Privateloc';
import { createTheme, ThemeProvider } from '@mui/material/styles';


const theme = createTheme({
  palette: {
    primary: {
      main: "#e22424"
    },
    secondary: {
      // This is green.A700 as hex.
      main: '#e22424',
    },
    white: {
      // This is green.A700 as hex.
      main: '#FFF',
    },
  },
});

function App() {
  const [locale, setLocale] = useState('fr');



  return (
    // isLoaded ?
    <PrivatelocProvider>
      <ThemeProvider theme={theme}>
        <IntlProvider locale={locale} messages={lang[locale]}>

          <Router>
            <Routes>
              <Route path="/" element={<Privateloc />} />

            </Routes>
          </Router>

        </IntlProvider>
      </ThemeProvider>
    </PrivatelocProvider>
    // : <><div className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}><img src={images.loader} alt="" /> </div></>)
  );
}

export default React.memo(App);

