import React from 'react'
import styled from "styled-components"
import ScrollAnimation from "react-animate-on-scroll";
import  images  from "../images/"
const DivC = styled.div`

padding-top:80px;
padding-bottom:80px;
overflow: hidden;
.subback{
    position: absolute;
    bottom: 30px;
    left: 0;
    right:0;
    height:60px;
    background-size: 30px 30px;
    background-repeat: repeat;
    opacity: .2;
}
.banner{
    max-width: 1200px;
    height: 360px;
    border-radius:25px;
    background-color: #e22424;
    width: 100%;
    position: relative;
    /* overflow: hidden; */
    box-shadow: 0px 0px 4px 0px #0004;
}
.phone{
    height: 100%;
    width:auto;
    transform: translateY(-20px);
}

.banner-body{
    height:100%;
    /* position: relative; */
}
.desc img{
    height:30px;
    width:auto;
}
h1{
    font-weight: 700;
}
p{
    font-size: 18px;
}
h1,p{
    color:white
}
@media screen and (max-width: 815px){
    padding-left:12px!important;
    padding-right:12px!important;
    .phone{
        position: absolute;
        bottom: -50px;
        right:-30px;
        height: 360px;
    }
    .banner{
        min-height:600px;
        
    }
    p{
        max-width: 300px;
    }
    .desc{
        margin:10px!important
    }
}
.app img{
    height:32px
}
.app a{
    overflow: hidden;
    border-radius:5px
}
`
function Banner() {
    return (
        <DivC className="d-flex justify-content-center p-5">
            <ScrollAnimation
                    className="flex-grow-1 d-flex justify-content-center" 
                    animateIn="banner-animation"
                >
            <div className="banner ps-4 pe-4">
                <div className="subback " style={{ backgroundImage: "url(" + images.pattern + ")" }}>

                </div>
                <div className='banner-body d-flex '>
                    <img src={images.phone} alt="" className="phone  phone-animation" />
                    <div className='desc d-flex flex-column justify-content-start ms-5'>
                        <div className='mt-5 mb-2'>
                        <img src={images.logow} alt="" style={{height:"48px"}} className="banner-an-item" />
                        </div>
                        <h1 className='mb-2 banner-an-item'>Une nouvelle ère de la livraison à la demande </h1>
                        <p className='banner-an-item'>Nous récupérons et livrons vos colis sur tout le territoire national.</p>
                        <div className='d-flex app banner-an-item'>
                            <a href="http://google.com" target="_blank" className='me-2'>
                                <img src={images.googleplay} alt="" />
                            </a>
                            <a href="http://google.com" target="_blank" >
                                <img src={images.appstore} alt="" />
                            </a>
                            
                            
                        </div>
                    </div>
                </div>
            </div>
            </ScrollAnimation>

        </DivC>
    )
}






export default Banner