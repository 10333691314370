



import logov from "./png RED.png"
// import logov from "./logov.png"
import logow from "./WHITE.png"
import phone from "./phone.png"
// import img_r from "./img-r.png"
import img_r from "./forgoun.png"
import moto from "./MOTO.png"
import appstore from "./appstore.svg"
import googleplay from "./googleplay.svg"

// import car from "./car.png"
const images={
    logov,
    img_r,moto,
    appstore,googleplay,logow,phone
}

export default images;